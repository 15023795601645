import React, { useState, useEffect } from 'react'
import * as JsSearch from 'js-search'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import '../css/_searchWeb.scss'
import '../css/_elements.scss'
import { CAREER_GENERAL_ROUTE } from '../utils/variables/generalRoutes'

import Layout from '../components/layout'
import Seo from '../components/seo'
import SearchBar from '../components/searchBar'

import Schedule from '../components/schedule'
import NoticesAndNovelties from '../components/noticesAndNovelties'
import NotesPreview from '../components/notesPreview'
import { Formulario } from '../components/form'

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'react-bootstrap'
import '../components/font-awesome'


const searchWeb = (props) => {
  const { data } = props
  const { 
    strapiInstitution: institution,
    allSitePage: {nodes: pagesPath},
    allStrapiOrganizationalUnit: {nodes: organizationalUnit},
    allStrapiDiary: {nodes: diaries},
    allStrapiNovelties: {nodes: novelties},
    allStrapiNotes: {nodes: notes},
    allStrapiUccCareer: {nodes: career},
    allStrapiIcdaCareer: {nodes: icdaCareers},
    allStrapiPages: {nodes: pages},
    allStrapiFjsCourses: {nodes: fjsCourses}
  } = data

  // Adding ICDA to the set of Organizational Units
  organizationalUnit[organizationalUnit.length -1].name.includes("ICDA - Escuela de Negocios") 
    ? null 
    : organizationalUnit.push({name: "ICDA - Escuela de Negocios" })

  // Creating the objects of each collection
  // Adding the collection field to identify which each record belongs to
  const diariesAll = diaries?.map((diary) => ({
    collection: "Diaries",
    btnAllCollection: "/agenda",  // Url del boton Ver Todas
    title: diary.title, 
    name: diary.title?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), // Normalizando para quitar acentos - Utilizo name para no quitarle los acentos al titulo de cada evento
    slug: diary.slug,
    date: diary.date,
    time: diary.time,
    place: diary.place,
    diary_category: diary.diary_category?.name,
    pageKeywords: diary.pageMetadata?.pageKeywords
  }))
  const noveltiesAll = novelties?.map((novelty) => ({
    collection: "Novelties",
    btnAllCollection: "/noticias", // Url del boton Ver Todas
    id: novelty.strapiId,
    title: novelty.title?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), // Normalizando para quitar acentos 
    slug: novelty.slug,
    summary: novelty.summary,
    thumbnail: novelty.thumbnail?.url,
    pageKeywords: novelty.pageMetadata?.pageKeywords
  }))
  const notesAll = notes?.map((note) => ({
    collection: "Notes",
    btnAllCollection: "/notas", // Url del boton Ver Todas
    id: note.strapiId,
    title: note.title?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), // Normalizando para quitar acentos 
    slug: note.slug,
    summary: note.summary,
    thumbnail: note.thumbnail?.url,
    pageKeywords: note.pageMetadata?.pageKeywords
  }))
  const careersAll = career?.map((career) => ({
    collection: "UCCcareer",
    btnAllCollection: "/propuesta-academica", // Url del boton Ver Todas
    title: career.name?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), // Normalizando para quitar acentos 
    name: career.name,
    slug: career.slug,
    organizational_unit: career.organizational_unit?.name,
    pageKeywords: career.pageMetadata?.pageKeywords
  }))
  const icdaCareersAll = icdaCareers?.map((career) => ({
    collection: "UCCcareer",
    title: career.name?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),  // Normalizando para quitar acentos 
    name: career.name,
    slug: career.slug,
    organizational_unit: "ICDA - Escuela de Negocios",
    pageKeywords: career.main?.keywords
  }))
  const pagesAll = pages?.map((page) => ({
    collection: "Pages",
    title: page.title?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), // Normalizando para quitar acentos
    name: page.title,
    slug: page.slug,
    path: pagesPath.find((pagePath) => { return pagePath.path?.includes(page.slug)}),
    pageKeywords: page.pageMetadata?.pageKeywords
  }))
  const fjsCoursesAll = fjsCourses?.map((course) => ({
    collection: "FJS Courses",
    btnAllCollection: "https://fjs.ucc.edu.ar/", // Url del boton Ver Todas
    title: course.name?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), // Normalizando para quitar acentos
    name: course.name.toLowerCase(),
    slug: course.slug,
    category: course.training_type?.title,
    pageKeywords: course.seo?.pageKeywords
  }))

  // Uniendo los datos en un solo Array
  const collectionQuery = diariesAll.concat(noveltiesAll).concat(notesAll).concat(careersAll).concat(icdaCareersAll).concat(pagesAll).concat(fjsCoursesAll)
  // console.log("Array FINAL ======================");
  // console.log(collectionQuery);
  // console.log("FINAL Array ======================");


  // SearchBar de collectionQuery
  const [queryResults, setQueryResults] = useState(collectionQuery)
  const [search, setSearch] = useState(null)
  
  const termFrequency = true
  const selectedSanitizer = "Lower Case"
  const removeStopWords = true

  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search('title')

    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      )
    }

    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()

    selectedSanitizer === 'Case Sensitive'
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('name'))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())

    dataToSearch.addIndex('title')
    dataToSearch.addIndex('name')
    dataToSearch.addIndex('category')
    dataToSearch.addIndex('pageKeywords')
    dataToSearch.addDocuments(collectionQuery) // adds the data to be searched
    setSearch(dataToSearch)
  }

  const handleSearch = (textInput) => {
    textInput.length === 0 ? setQueryResults(collectionQuery) : setQueryResults(search.search(textInput))
    textInput.length === 0
  }

  // Resultados de la busqueda
  const searchResult = queryResults.filter(
    (result) => result
  )  
  // console.log("SearchResult ===================");
  // console.log(searchResult);
  // console.log("ResultSearch ===================")


  // Divido los resultados segun la coleccion que pertenecen
  var diariesSearch = []
  var noveltiesSearch = []
  var notesSearch = []
  var careerSearch = []
  var pagesSearch = []
  var fjsSearch = []
  
  searchResult.map((search) => {
    if (search?.collection && search.collection === "Novelties") {
      noveltiesSearch.push(search)
    } else if (search?.collection && search.collection === "Diaries") { 
      diariesSearch.push(search)
    } else if (search?.collection && search.collection === "Notes") { 
      notesSearch.push(search)
    } else if (search?.collection && search.collection === "UCCcareer") {
      careerSearch.push(search)
    } else if (search?.collection && search.collection === "Pages") {
      pagesSearch.push(search)
    } else if (search?.collection && search.collection === "FJS Courses") {
      fjsSearch.push(search)
    }
  })

  // console.log("Novelties" + noveltiesSearch.length);
  // console.log("Diaries" + diariesSearch.length);
  // console.log("Career" + careerSearch.length);

  // Accordion Collapse
  function CustomToggle({ children, eventKey }) {
    const [activeStates, setActiveState] = useState(false)
    
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      setActiveState(!activeStates)
    })

    let classActive = activeStates ? 'active' : ''
    return (
      <button
        type="button"
        className={`btn ${classActive}`}
        onClick={decoratedOnClick}
      >
        {children} 
        <FontAwesomeIcon icon={['fas', 'chevron-down']} size="sm" />
      </button>
    )
  }

  
  // Actualizando el buscador cuando apreto VER MAS en paginas
  useEffect(() => {
    rebuildIndex()
  }, [])

  // Contador utilizado en el boton de VER MAS para Paginas
  const [ contador, setContador ] = useState(12)

  // Contador utilizado en el boton de VER MAS para FJS Courses
  const [ contadorFJS, setContadorFJS ] = useState(12)

  function handleClickPages() {
    setContador(contador + 14)
  }
  function handleClickFJS() {
    setContadorFJS(contadorFJS + 14)
  }

  if (searchResult.length === 0) { // Reseteo el contador cuando no se encontraron resultados
    if (contador !== 16) {
      setContador(16) 
    }
  } 

  // Mensaje de No se encontraron resultados
  function messageResult(nameResult, btnAll = null) {
    return (
      <div className='container mb-5'>
        <div className="d-flex align-items-center justify-content-between mb-3 novelties-title">
          <h2 className={`title-secondary font-weight-extra-bold mb-0 ucc-text-blue`}>
            {`No se encontraron resultados ${nameResult}`}
          </h2>

          {btnAll !== null ?
            <Link
              to={`${btnAll}`}
              className={`btn btn-sm btn-outline-dark  cards-section-button`}
            >
              Ver todas
            </Link>
          : null }
        </div>
        <h5>Intente buscar por otro Nombre, Título o Categoría</h5>
      </div>
    )
  }

  // Variable con el texto a mostrar cuando no hay resultados de la busqueda
  let nameResult

  return (
    <Layout page={institution} indexHeader={false} header={institution.header}>
      <Seo />
      <section className='searchSection'>
        <div className="">

          <div className='searchWeb row justify-content-center align-items-center mb-5'>
            <div className='container box-search row col-12 col-md-6 justify-content-center align-items-center'>
              <div className='container col-12 col-md-10'>
                <h3>Realizá tu búsqueda</h3>
                <SearchBar onQuery={handleSearch} placeholder={'Buscá por nombre de Carrera, Noticia, Evento, Página... '} />
              </div>
            </div>
          </div>

          <div className='mb-5'> 
            {/* Carreras */}
            {careerSearch.length !== 0 ? (
              <div className='container mb-5'>
                {/* Title */}
                <div className="d-flex align-items-center justify-content-between mb-3 novelties-title">
                  {
                    <h2 className={`title-secondary text-uppercase font-weight-extra-bold mb-0 ucc-text-blue`}>
                      {'CARRERAS DE GRADO Y POSGRADO'}
                    </h2>
                  }
                  <Link
                    to={`${careersAll[0].btnAllCollection}`}
                    className={`btn btn-sm btn-outline-dark  cards-section-button`}
                  >
                    Ver todas
                  </Link>
                </div>
                {/* Desplegable de Opciones */}
                <Accordion
                    defaultActiveKey="0"
                    className="accordionSearch w-100"
                  >
                    <Row xs={1} md={2} lg={2} xl={2} xxl={3}>     
                      {organizationalUnit.map((organization, i) => {
                        var orgUnit = false // Validando que la OU no se repita
                        return careerSearch.map((career) => {
                          // Generando el desplegable segun cada Unidad Organizacional
                          if (career.organizational_unit === organization.name && orgUnit === false) {
                            orgUnit = true
                            return (
                              <Col>
                                <Card key={i} className='mb-2'>
                                  <Card.Header>
                                    <CustomToggle
                                      eventKey={i+1}
                                      className="btn-unid-acad"
                                    >
                                      <p className="mb-0 text-left">
                                        {organization.name}
                                      </p>
                                    </CustomToggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={i+1}>
                                    <Card.Body>
                                      {careerSearch.map((career) => {
                                        if (career.organizational_unit === organization.name) {
                                          return (
                                            <Link
                                              to={organization.name === "ICDA - Escuela de Negocios" ? `https://icda.ucc.edu.ar/${CAREER_GENERAL_ROUTE}/${career.slug}` : `/carreras/${career.slug}`}
                                              target="_blank"
                                            >
                                              <Button className="btn-carrers">
                                                {career.name}
                                              </Button>
                                            </Link>
                                          )
                                        }
                                      })}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Col>
                            )
                          }
                        })
                      })}
                    </Row>
                  </Accordion>
              </div>  
            ) : (null)}

            {/* Diaries */}
            {diariesSearch.length !== 0 ? (
              <div>
                <Schedule 
                  isHomeUcc={true} 
                  diaries={diariesSearch}
                  title={"Agenda"} 
                />
              </div>
            ) : (null)}
          
            {/* Novelties */}
            {noveltiesSearch.length !== 0 ? (
              <div>
                <NoticesAndNovelties
                  novelties={noveltiesSearch}
                  title={"Noticias"}
                  linkUrl={true}
                />
              </div>
            ) : (null)}

            {/* Notes */}
            {notesSearch.length !== 0 ? (
              <div className="mt-5">
                <NotesPreview
                  notes={notesSearch} 
                  isHomeUcc={false}
                  view={3}
                />
              </div>
            ) : (null)}

            {/* Pages */}
            {pagesSearch.length !== 0 ? (
              <div className='container mb-5'>
                {/* Title */}
                <div className="d-flex align-items-center justify-content-between mb-3 novelties-title">
                  {
                    <h2 className={`title-secondary text-uppercase font-weight-extra-bold mb-0 ucc-text-blue`}>
                      {'PÁGINAS UCC'}
                    </h2>
                  }
                </div>

                {/* Desplegable de Opciones */}
                <Accordion
                  defaultActiveKey="0"
                  className="accordionSearch w-100"
                >                          
                  <Card key={1} className='mb-2'>
                    <Card.Header className="btn-pages-ucc">
                      <CustomToggle eventKey={1}>
                        <p className="mb-0 text-left">
                          {"Resultados del sitio"}
                        </p>
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={1}>
                      <Card.Body>
                        {/* Ordenando en 2 columnas los resultados */}
                        <Row xs={1} md={2} lg={2} xl={2} xxl={3}>     
                          {pagesSearch.map((page, i) => {
                            if (i < contador) {
                              return (
                                <Col>
                                  <Button 
                                    href={`${page.path.path}`} 
                                    target="_target" 
                                    className="btn-pages"
                                  >
                                    {page.name}
                                  </Button>
                                </Col>
                              )
                            }
                          })}
                        </Row>
                        <Row>
                          <Button className="btn-mas" onClick={handleClickPages}>
                            {"VER MÁS"}
                          </Button>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>  
            ) : (null)}

            {/* FJS Courses */}
            {fjsSearch.length !== 0 ? (
              <div className='container mb-5'>
                {/* Title */}
                <div className="d-flex align-items-center justify-content-between mb-3 novelties-title">
                  {
                    <h2 className={`title-secondary text-uppercase font-weight-extra-bold mb-0 ucc-text-blue`}>
                      {'Formación Continua'}
                    </h2>
                  }
                </div>

                {/* Desplegable de Opciones */}
                <Accordion
                  defaultActiveKey="0"
                  className="accordionSearch w-100"
                >                          
                  <Card key={1} className='mb-2'>
                    <Card.Header className="btn-pages-ucc">
                      <CustomToggle eventKey={1}>
                        <p className="mb-0 text-left">
                          {"Cursos"}
                        </p>
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={1}>
                      <Card.Body>
                        {/* Ordenando en 2 columnas los resultados */}
                        <Row xs={1} md={2} lg={2} xl={2} xxl={3}>     
                          {fjsSearch.map((course, i) => {
                            if (i < contadorFJS) {
                              return (
                                <Col>
                                  <Button 
                                    href={`https://fjs.ucc.edu.ar/${course.slug}`} 
                                    target="_target" 
                                    className="btn-pages"
                                  >
                                    {course.name}
                                  </Button>
                                </Col>
                              )
                            }
                          })}
                        </Row>
                        <Row>
                          <Button className="btn-mas" onClick={handleClickFJS}>
                            {"VER MÁS"}
                          </Button>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>  
            ) : (null)}
          </div>

          {/* Mensaje de resultado NO encontrado */}
          {careerSearch.length === 0 ? (
            nameResult = "para Carreras de Grado y Posgrado",
            messageResult(nameResult, careersAll[0].btnAllCollection)     
          ) : null}
          {diariesSearch.length === 0 ? (
            nameResult = "en Agenda",
            messageResult(nameResult, diariesAll[0].btnAllCollection)     
          ) : null}
          {noveltiesSearch.length === 0 ? (
            nameResult = "en Noticias",
            messageResult(nameResult, noveltiesAll[0].btnAllCollection)     
          ) : null}
          {pagesSearch.length === 0 ? (
            nameResult = "en Páginas",
            messageResult(nameResult)     
          ) : null}
          {fjsSearch.length === 0 ? (
            nameResult = "en Cursos de Formación Continua",
            messageResult(nameResult, fjsCoursesAll[0].btnAllCollection)     
          ) : null}

          {/* Formulario de contacto */}
          {institution.form && institution.form.form.id !== null && (
            <Formulario
              idForm={institution.form.form.id}
              title={institution.form?.title}
              key={`formId-${institution.form.form.id}`}
              receiverGeneral={institution.form.interest_receiver?.receiver}
              typeGeneral={institution.form.interest_type}
            />
          )}
        </div>
      </section>
    </Layout>
  )
}

export default searchWeb

searchWeb.propTypes = {
  data: PropTypes.object.isRequired
}

export const searchWebQuery = graphql`
  query {
    strapiInstitution {
      name
      id
      primaryLogo {
        image {
          url
        }
        url
      }
      secondaryLogo {
        image {
          url
        }
      }
      secondaryLogoDark {
        image {
          url
        }
      }
      form {
        id
        title
        form {
          id
        }
      }
      navbar {
        url
        name
        icon {
          code
          type
        }
        ExternalLink {
          name
          url
          id
          icon {
            code
            type
          }
        }
        organizationalUnit {
          name
          icon {
            code
            type
          }
          organizational_unit {
            id
            name
            slug
          }
          id
        }
        pages {
          name
          icon {
            code
            type
          }
          page {
            id
            name
            slug
          }
          id
        }
        page {
          slug
          name
          id
        }
        organizational_unit {
          id
          name
          slug
        }
      }
    }
    allStrapiOrganizationalUnit {
      nodes {
        name
      }
    }
    allStrapiDiary(
      filter: {academic_unit: {name: {ne: "ICDA"}}}
      limit: 20
      sort: { fields: date, order: DESC } 
    ) {
      nodes {
        title
        slug
        pageMetadata {
          pageKeywords
        }
        date(formatString: "YYYY/MM/DD", locale: "es")
        time
        place
        diary_category {
          name
        }
      }
    }
    allStrapiNovelties(
      filter: {academic_unit: {name: {ne: "ICDA"}}}
      sort: { fields: date, order: DESC } 
    ) {
      nodes {
        strapiId
        title
        slug
        pageMetadata {
          pageKeywords
        }
        summary
        destacadoHome
        thumbnail {
          url
        }      
      }
    }
    allStrapiNotes(
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        strapiId
        title
        slug
        date
        summary
        thumbnail {
          url
        }
      }
    }
    allStrapiUccCareer {
      nodes {
        name
        slug
        pageMetadata {
          pageKeywords
        }
        organizational_unit {
          name
        }
        header {
          slide {
            backgroundImage {
              url
            }
          }
        }
      }
    }
    allStrapiIcdaCareer {
      nodes {
        name
        slug
        main {
          keywords
        }
      }
    }
    allStrapiPages(sort: {order: ASC, fields: name}) {
      nodes {
        title
        slug
        pageMetadata {
          pageKeywords
        }
      }
    }
    allStrapiFjsCourses {
      nodes {
        name
        slug
        training_type {
          title
        }
        seo {
          pageKeywords
        }
      }
    }
    allSitePage {
      nodes {
        path
      }
    }
  }
`
